import React, { Component } from "react"
import Helmet from 'react-helmet'; 

// Common components & Layout 
import Layout from "../../layouts"; 
import { Container } from "react-bootstrap";

// Individual components that make up the page
import Newsletter from "../../components/newsletter/newsletter";
import Hero from "../../components/common/Hero"; 
import Section from "../../components/common/Section"; 
import SEO from "../../components/common/SEO"; 

class Terms extends Component {
  render() {
      return (
          <Layout>
            <Helmet bodyAttributes={{ class: 'terms-page' }} />            
            <SEO post={{
              title: "Facts First Terms And Conditions"              
            }}/>
            <Hero title='Terms of Use'></Hero>
            <Container>       
            <Section name="terms_of_use" header="Terms of Use"/>                
            </Container>     
            <Newsletter />       
          </Layout>
      ); 
  }
}

export default Terms; 